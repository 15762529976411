// javascript/controllers/reservations_controller.js
import {Controller} from "stimulus";
import {updateDataLayer, zeroPadding} from "./../helpers";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "stepOneSubmit",
    "reservationsMain",
    "exchangeCarSubmitted",
    "saleCarTimer",
    "saleCarInfo",
    "stepOneForm",
    "stepTwoFormOne",
    "stepTwoForm",
    "stepThreeForm",
    "stepTwoFormFinancing",
    "finalStepForm",
    "instantForm",
  ];
  fields_with_errors = [];
  timer = null;
  main_routes = "varaukset";
  reservation_message = {}
  connect() {
    this.getCarId();
    let exchange_price_container = document.getElementById(
      "exchange-car-submitted"
    );
    if (exchange_price_container) {
      this.updateExchangeCarPrice();
    }

    let url_path = location.pathname.split("/");
    if (
      url_path.indexOf("leasing_varaukset") >= 0 ||
      url_path.indexOf("liisaa") >= 0
    ) {
      this.main_routes = "leasing_varaukset";
    }

    this.updateLeaseCardPrice();
    this.setSelectedLeasingOptions();
  }

  updateLeaseCardPrice(){
    let price_field = document.querySelectorAll('.reservation-lease-monthly-price');
    if(price_field){
      let lease_options = JSON.parse(sessionStorage.getItem('leaseOptions'));
      if(lease_options && lease_options['price']){
        price_field.forEach(span => {
          let current_price = span.dataset.price;
          let new_price = lease_options.price.toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' });
          span.textContent = new_price + '/kk';
        });
      }
    }
  }
  setSelectedLeasingOptions(){
    let leasing_description = document.getElementById("lease_car_reservation_leasing_description");
    if(leasing_description){
      let lease_options = JSON.parse(sessionStorage.getItem('leaseOptions'));
      if(lease_options && lease_options['price']){
        leasing_description.value = JSON.stringify(lease_options);
      }
    }
  }
  reserverInfo(event) {
    event.preventDefault();
    let data = "";
    let quick_reservation = document.getElementById("instant-submit");
    if (quick_reservation) {
      data = new FormData(this.instantFormTarget);
      let reservation_terms_accepted = document.getElementById("reservation_terms_accepted");
      if(reservation_terms_accepted && !reservation_terms_accepted.checked){
        reservation_terms_accepted.classList.add("is-invalid");
        return;
      }
    } else {
      data = new FormData(this.stepOneFormTarget);
    }
    let { car_id, res_id, car_price, easy_id, car_info } = this.getCarId();
    updateDataLayer(easy_id, car_price, "client information");
    this.dataLayerEcommerceSteps("checkout", 1, car_info);
    let reservation_id = res_id ? res_id : "";
    let url = `/ajoneuvot/${car_id}/${this.main_routes}/${reservation_id}`;
    Rails.ajax({
      type: "post",
      url: url,
      data: data,
      error: (response) => {
        let error_fields = Object.keys(response).map((key) => {
          if (this.main_routes === "leasing_varaukset") {
            return `lease_car_reservation_${key}`;
          } else {
            return `reservation_${key}`;
          }
        });
        error_fields.forEach((field_name) => {
          let ele = document.getElementById(field_name);
          if (ele) {
            ele.classList.add("is-invalid");
          }
        });
        this.fields_with_errors = error_fields;
      },
    });
  }

  setSelectedItem(e) {
    let element = e.target;

    // Get the id of the element
    let id = element.id;

    // Check if the id includes 'has_exchange'
    if (id.includes('has_exchange')) {
      this.reservation_message["has_exchange"] = "Minulla on vaihtoauto: Kyllä";
    }

    // Check if the id or 'for' attribute includes 'need_financing'
    if (id.includes('need_financing')) {
      this.reservation_message["need_financing"] = "Haluan rahoitustarjouksen: Kyllä";
    }

    // If message has values, join them into one string
    if (Object.keys(this.reservation_message).length > 0) {
      let details = Object.values(this.reservation_message).join(", ");

      // Get the element by id 'reservation_message' and set its value to the details string
      let reservationMessageElement = document.getElementById('reservation_message');
      if (reservationMessageElement) {
        reservationMessageElement.value = details;
      }
    }
  }

  toggleValidation(e){
    if (e.target.classList.contains("is-invalid")){
      e.target.classList.remove("is-invalid")
    }
  }

  addExchangeCar(event) {
    event.preventDefault();
    let { car_id, car_price, easy_id, car_info } = this.getCarId();
    updateDataLayer(easy_id, car_price, "trade-in");
    this.dataLayerEcommerceSteps('checkout', 2, car_info);
    let sell_car_form_url = this.stepTwoFormTarget.action;
    let form_data = new FormData(this.stepTwoFormTarget);
    Rails.ajax({
      type: "patch",
      url: sell_car_form_url,
      data: new FormData(this.stepTwoFormTarget),
      error: (response) => {
        let error_fields = Object.keys(response).map((key) => {
          let key_values = key.split(".");
          // reservation_sell_car_attributes_reg_num
          return `reservation_${key_values[0]}_attributes_${key_values[1]}`;
        });
        error_fields.forEach((field_name) => {
          let ele = document.getElementById(field_name);
          if (ele) {
            ele.classList.add("is-invalid");
          }
        });
        this.fields_with_errors = error_fields;
      },
    });
  }

  addFinancingApplication(event) {
    event.preventDefault();
    let { car_id, car_price, easy_id, car_info } = this.getCarId();
    updateDataLayer(easy_id, car_price, "finance");
    this.dataLayerEcommerceSteps('checkout', 3, car_info);
    let financing_form_url = this.stepTwoFormFinancingTarget.action;
    let form_data = new FormData(this.stepTwoFormFinancingTarget);
    Rails.ajax({
      type: "patch",
      url: financing_form_url,
      data: new FormData(this.stepTwoFormFinancingTarget),
      success: (response) => {
        let {status, reservation_url} = response;
        if(status && status === 'ok'){
          //updateDataLayer(easy_id, car_price, "retainer");
          location.href = reservation_url;
        }
      },
      error: (response) => {
        let error_fields = Object.keys(response).map((key) => {
          let key_values = key.split(".");
          // reservation_sell_car_attributes_reg_num
          return `reservation_${key_values[0]}_attributes_${key_values[1]}`;
        });
        error_fields.forEach((field_name) => {
          let ele = document.getElementById(field_name);
          if (ele) {
            ele.classList.add("is-invalid");
          }
        });
        this.fields_with_errors = error_fields;
        if (error_fields.length > 0) {
          let first_element = document.getElementById(error_fields[0]);
          first_element.scrollIntoView();
        }
      },
    });
  }

  getCarId() {
    try {
      let html_element = this.reservationsMainTarget;
      let car_id = "",
          res_id = "",
          car_price = "",
          easy_id = "",
          car_info = {};
      if (html_element) {
        car_id = html_element.dataset.carPlate;
        res_id = html_element.dataset.reservationType;
        car_price = parseInt(html_element.dataset.carPrice, 10);
        easy_id = parseInt(html_element.dataset.easyId, 10);
        if (html_element.dataset.carInfo) {
          car_info = JSON.parse(html_element.dataset.carInfo);
        }
        car_info["easy_ad_id"] = easy_id;
        car_info["price"] = car_price;
      }

      return { car_id, res_id, car_price, easy_id, car_info };
    }
    catch (e) {
      console.log(e);
    }
  }

  removeErrorIndicator(event) {
    let ele_id = event.target.id;
    if (
      this.fields_with_errors.length > 0 &&
      ele_id &&
      this.fields_with_errors.indexOf(ele_id) >= 0
    ) {
      let current_element = document.getElementById(ele_id);
      if (current_element) {
        current_element.classList.remove("is-invalid");
      }
    }
  }

  leasingContractApplied() {
    let financing_container = this.financingContainerTarget;
    if (financing_container) {
      financing_container.classList.toggle("d-none");
    }
  }

  toggleVisibility(event) {
    let item = event.target;
    let ele_id = item.dataset.name;
    let ele_type = item.dataset.type;
    let { car_price, easy_id } = this.getCarId();
    if (ele_id) {
      document.getElementById(ele_id).classList.toggle("d-none");
      if (ele_id === "financing-form-container") {
        document
          .getElementById("proceed-next-step-2")
          .classList.toggle("d-none");
        document
          .getElementById("driving-info-section-next")
          .classList.toggle("d-none");
      }
      if (ele_type === "next") {
        item.classList.toggle("d-none");
        document.getElementById(`${ele_id}-next`).classList.toggle("d-none");
      }

      if (ele_type === "want-leasing-contract") {
        let form_data = new FormData(this.stepTwoFormOneTarget);
        this.handleCheckboxRequest(form_data);
      }

      if (ele_id === "payment-info-section") {
        let form_data = new FormData(this.stepThreeFormTarget);
        this.handleCheckboxRequest(form_data);
      }
    }
  }

  handleCheckboxRequest(data) {
    let { car_id, res_id } = this.getCarId();
    let url = `/ajoneuvot/${car_id}/${this.main_routes}/${res_id}`;
    Rails.ajax({
      type: "post",
      url: url,
      data: data,
    });
  }

  updateExchangeCarPrice() {
    let sell_car_container = this.exchangeCarSubmittedTarget;
    let end_time = null;
    if (sell_car_container) {
      let started_at = sell_car_container.dataset.started;
      let is_price_given = sell_car_container.dataset.priceGiven;
      if (started_at && is_price_given === "no") {
        let created_at = new Date(started_at);
        /*         let end_time = new Date(
          new Date(started_at).setMinutes(new Date(started_at).getMinutes() + 4)
        ); */
        end_time = new Date(started_at).setMinutes(
          new Date(started_at).getMinutes() + 10
        );
      }
    }

    if (end_time) {
      this.timer = setInterval(() => {
        let current_time = new Date().getTime();
        let distance = end_time - current_time;
        if (distance < 0) {
          clearInterval(this.timer);
        } else {
          this.displayCountDownTimer(distance);
        }
      }, 1000);
    }
  }

  displayCountDownTimer(distance) {
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    let timer_element = this.saleCarTimerTarget;
    if (timer_element) {
      timer_element.textContent = `${zeroPadding(minutes)}:${zeroPadding(
        seconds
      )}`;
    }

    if (seconds % 25 === 0) {
      this.getPriceUpdate();
    }
  }

  getPriceUpdate() {
    let { car_id, res_id } = this.getCarId();
    let url = `/ajoneuvot/${car_id}/${this.main_routes}/get_sell_car_price?res_id=${res_id}`;
    Rails.ajax({
      type: "get",
      url: url,
      success: (response) => {
        let { price } = response;
        if (price > 0) {
          clearInterval(this.timer);
          this.saleCarTimerTarget.textContent = `${price} €`;
          this.saleCarInfoTarget.textContent = "";
          let sell_car_price = document.getElementById("sell_car_given_price");
          let price_text = sell_car_price.textContent;
          if (price_text.split(' ').indexOf('ei') >= 0) {
            let sell_car_price_tags = document.querySelectorAll('.sell_car_given_price')
            let reservation_car_total_price = document.querySelectorAll('.reservation_car_total_price')
            sell_car_price_tags.forEach((tag, index) => {
              tag.textContent = `- ${price}€`;
              let current_price = reservation_car_total_price[index].textContent.replace(/ /g,'');
              let total = parseInt(current_price.split(",")[0], 10) - parseInt(price, 10)
              reservation_car_total_price[index].textContent = `${total.toFixed(0)},00€`
            })
          }
        }
      },
      error: (response) => {
      },
    });
  }

  addPackage(event) {
    let item = event.target;
    let checked = item.checked;
    let main_form = this.stepThreeFormTarget;
    let labels = main_form.querySelectorAll("label");
    labels.forEach((label) => {
      if (label.htmlFor === item.id) {
        if (checked) {
          label.textContent = "Poista";
        } else {
          label.textContent = "Lisää";
        }
      }
    });
    let step_3_btn = document.getElementById("proceed-next-step-3");
    let step_3_label = document.getElementById("payment-info-section-next");
    let payment_info = document.getElementById("payment-info-section");
    if (
      step_3_btn.classList.contains("d-none") &&
      step_3_label.classList.contains("d-none")
    ) {
      step_3_btn.classList.remove("d-none");
      step_3_label.classList.remove("d-none");
      payment_info.classList.add("d-none");
    }
  }

  finalSubmission() {
    /*     let form = new FormData(this.finalStepFormTarget);
    let { car_id, res_id } = this.getCarId();
    let url = `/ajoneuvot/${car_id}/${this.main_routes}/${res_id}`;
    Rails.ajax({
      type: "post",
      url: url,
      data: form,
    }); */
  }

  deliveryInfoSubmitted(e){
    let { car_id, car_price, easy_id, car_info } = this.getCarId();
    updateDataLayer(easy_id, car_price, "retainer");
    this.dataLayerEcommerceSteps('checkout', 4, car_info);
  }

  dataLayerEcommerceSteps(event_name, step, car_info){
    if (window.dataLayer) {
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
      'event': event_name,
      'ecommerce': {
        'checkout': {
          'actionField': {'step': step},
          'products': [{
            'name': `${car_info['make']} ${car_info['model']}`,
            'id': car_info['easy_ad_id'],
            'price': car_info['price'],
            'brand': `${car_info['make']}`,
            'category': `${car_info['drive_type']}`,
            'variant': `${car_info['color']}`,
            'quantity': 1,
            'metric1': car_info['year'],					//Integer, ie '2015'
            'metric2': car_info['mileage'],					//Integer, ie '149203'
            'metric3': car_info['price'],			  //Decimal, ie '14900.00'
            'dimension7': `${car_info['make']}`,
            'dimension8': `${car_info['model']}`,
            'dimension9': `${car_info['color']}`,
            'dimension10': `${car_info['drive_type']}`,
            'dimension11': car_info['trailer_hitch']
          }]
        }
      },
      });
    }
  }
}
