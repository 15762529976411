// javascript/controllers/fixed_panel_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Initialize scroll tracking state
    this.lastScrollY = window.scrollY;
    this.isAbsolute = false;
    this.originalTop = 140; // Store as class property
    this.gap = 40; // Store as class property

    // Force initial position check
    this.checkInitialPosition();
  }

  checkInitialPosition() {
    const fixedRightPanel = document.querySelector(".fixed-right-panel");
    if (fixedRightPanel) {
      // Always start with fixed position
      this.switchToFixed(fixedRightPanel);
    }
  }

  trackFeaturedCars() {
    const fixedRightPanel = document.querySelector(".fixed-right-panel");
    const featuredCarsSection = document.getElementById("more-similar-cars");

    // Early return if elements don't exist
    if (!fixedRightPanel || !featuredCarsSection) return;

    const featuredRect = featuredCarsSection.getBoundingClientRect();
    const panelRect = fixedRightPanel.getBoundingClientRect();

    // Calculate the bottom of the fixed panel with gap
    const panelBottomWithGap = panelRect.top + panelRect.height + this.gap;

    // Determine scroll direction
    const currentScrollY = window.scrollY;
    const scrollingDown = currentScrollY > this.lastScrollY;
    this.lastScrollY = currentScrollY;

    // Force fixed position when at the top of the page
    if (currentScrollY < this.originalTop) {
      if (this.isAbsolute) {
        this.switchToFixed(fixedRightPanel);
      }
      return;
    }

    // Check if we should switch positions
    const shouldSwitchToAbsolute =
      !this.isAbsolute &&
      scrollingDown &&
      featuredRect.top <= panelBottomWithGap;

    const shouldSwitchToFixed =
      this.isAbsolute &&
      !scrollingDown &&
      (featuredRect.top > panelBottomWithGap ||
        currentScrollY < this.originalTop);

    if (shouldSwitchToAbsolute) {
      this.switchToAbsolute(fixedRightPanel, featuredRect, panelRect);
    } else if (shouldSwitchToFixed) {
      this.switchToFixed(fixedRightPanel);
    }
  }

  switchToAbsolute(panel, featuredRect, panelRect) {
    panel.style.position = "absolute";
    const absoluteTop =
      window.scrollY + featuredRect.top - panelRect.height - this.gap;
    panel.style.top = `${absoluteTop}px`;
    this.isAbsolute = true;
  }

  switchToFixed(panel) {
    panel.style.position = "fixed";
    panel.style.top = `${this.originalTop}px`;
    this.isAbsolute = false;
  }
}
