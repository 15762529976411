// javascript/controllers/single_car_controller.js
import { Controller } from "stimulus";
import { updateDataLayer, updateDataLayerEvents } from "./../helpers";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["mainCurrentImage", "popUpImagesIcons"];

  connect() {
    document.getElementById("navigation").classList.add("shadow-sm");
    this.updatePageViews();
  }

  changeMainImage(event) {
    let target_item = event.target;
    let target_src = target_item.src;
    let main_image = document.getElementById("main-current-image");

    if (target_src) {
      let inlineCarousel = document.querySelectorAll(
        ".inline-carousel-indicator"
      );
      let id = target_item.dataset.itemIndex;
      let found_item = inlineCarousel[id];

      if (found_item) {
        inlineCarousel.forEach((item) => {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          }
        });
        found_item.classList.add("active");
        this.setCurrentImageCount(id);
      }

      //main_image.src = target_src;
    }
  }

  activeActiveButton(event) {
    let currrent_target = event.target;
    let current_btn_type = currrent_target.dataset.buttonType;
    let container = document.getElementById("popup-images-icons");
    let buttons = container.getElementsByTagName("button");
  }

  startReservation(e) {
    let html_element = document.getElementById("single-car-main");
    let car_price = "",
      easy_id = "",
      car_info = {};
    if (html_element) {
      car_price = parseInt(html_element.dataset.carPrice, 10);
      easy_id = parseInt(html_element.dataset.easyId, 10);
      car_info = JSON.parse(html_element.dataset.carInfo);
      car_info["easy_ad_id"] = easy_id;
      car_info["price"] = car_price;
      let monthly_payment = car_info["monthly_payment"];
      let fuel_consumption_combined = car_info["combined"];
      if (monthly_payment) {
        car_info["monthly_payment"] = parseInt(monthly_payment, 10);
      }
      if (fuel_consumption_combined) {
        car_info["combined"] = parseFloat(fuel_consumption_combined);
      }
      updateDataLayer(easy_id, car_price, "start reservation");
      updateDataLayerEvents("addToCart", car_info);
    }
  }

  showWhatsappNumber(e) {
    let main_element = e.target;
    main_element.innerHTML = `
        <i class="d-block fab fa-whatsapp fs-22 text-white pe-none"></i>
        <span class="position-absolute start-50 rounded-3 whatsapp-rounded-btn-show pe-none">050 413 0770</span>
    `;
  }

  toggle360Image(e) {
    let element = e.target;
    let url = element.dataset.url;
    let parent_id = element.dataset.bsTarget;
    if (url && parent_id) {
      let parent_element = document.querySelector(parent_id);
      if (parent_element) {
        parent_element.innerHTML = `<iframe
                            src="${url}"
                            class="image-frame"
                          ></iframe>`;
      }
    }
  }

  // dataLayer product click
  updateProductionObj(e) {
    let ele = e.target;
    let link_tag = ele.closest("a");
    if (link_tag) {
      let make = link_tag.dataset.make;
      let model = link_tag.dataset.model;
      let easy_id = link_tag.dataset.easyId;
      let body_type = link_tag.dataset.bodyType;
      let drive_type = link_tag.dataset.driveType;
      let price = link_tag.dataset.price;
      let id = link_tag.dataset.carId;
      let year = link_tag.dataset.year;
      let mileage = link_tag.dataset.mileage;
      let hitch = link_tag.dataset.hitch;
      let color = link_tag.dataset.color;
      if (window.dataLayer) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: "promotionClick",
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  name: `${make} ${model}`, // Name or ID is required.
                  id: parseInt(easy_id, 10),
                  position: parseInt(id, 10),
                },
              ],
            },
          },
          eventCallback: function () {
            document.location = link_tag.href;
          },
        });
      }
      sessionStorage.setItem("productClick", location.pathname);
    }
    return false;
  }

  goBack(e) {
    let target = e.target;
    let id = target.dataset.name;
    let carType = target.dataset.carType;
    let url =
      sessionStorage.getItem("productClick") ||
      sessionStorage.getItem("uj/x/uywY34Q&.'?LZxkd&");
    if (url) {
      sessionStorage.setItem("3nvU62110TyO2d@t8fdGYZcg", id);
      location.href = url;
    } else {
      location.href = carType === "lease" ? "/yksityisleasing" : "/osta-auto";
    }
  }

  showAccVideo(e) {
    let target = e.target;
    let videoLink = target.dataset.videoLink;
    let accModal = document.getElementById("accVideoModal");
    let modalBody = accModal.querySelector(".modal-body");

    if (videoLink && modalBody) {
      modalBody.innerHTML = `
        <div class="w-100">
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe
              src="${videoLink}?autoplay=true&cc_load_policy=true&mute=true&loop=1"
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              class="rounded-8"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      `;
    }
  }

  updatePageViews() {
    let main = document.getElementById("single-car-main");
    let add_view = false;
    if (main) {
      let status = JSON.parse(main.dataset.status);
      let id = parseInt(main.dataset.easyId, 10);
      if (!status) {
        let status_count = JSON.parse(sessionStorage.getItem("status_count"));
        if (status_count) {
          if (status_count.indexOf(id) < 0) {
            let updated_views = [...status_count, id];
            sessionStorage.setItem(
              "status_count",
              JSON.stringify(updated_views)
            );
            add_view = true;
          }
        } else {
          sessionStorage.setItem("status_count", JSON.stringify([id]));
          add_view = true;
        }

        if (add_view) {
          Rails.ajax({
            url: "/ajoneuvot/car_status?id=" + id,
            type: "GET",
          });
        }
      }
    }
  }

  importGoBack(e) {
    window.history.back();
  }

  setCurrentImageCount(count) {
    document.querySelector(".current-image-count").innerHTML =
      parseInt(count, 10) + 1;
  }

  updateImageCount(e) {
    let controller = this;
    setTimeout(() => {
      let element = e.target;
      let is_next = Array.from(element.classList).some((cls) =>
        cls.startsWith("carousel-control-next")
      );
      let is_prev = Array.from(element.classList).some((cls) =>
        cls.startsWith("carousel-control-prev")
      );
      let total_count = document.getElementById("carouselCarIndicatorsInline")
        .dataset.count;
      let all_images_container = document.querySelectorAll(".car-img-display");
      let count = null;
      all_images_container.forEach((container) => {
        let is_active = container.classList.contains("active");
        if (is_active) {
          count = container.dataset.count;
        }
      });
      if (count) {
        if (is_next) {
          count = parseInt(count, 10) + 1;
          if (count >= total_count) {
            count = 0;
          }
        } else if (is_prev) {
          count = parseInt(count, 10) - 1;
          if (count < 0) {
            count = total_count - 1;
          }
        }
        controller.setCurrentImageCount(count);
      }
    }, 100);
  }
}
